import React, {useEffect, useState} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Scan, Landing, Login, View, Manage, Signup} from "./screens";
import axios from "axios";
import globals from "./global";

const App = () => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${globals.server_address}/check-auth`, {
                withCredentials: true
            })
            .then(function (res) {
                setUser(res.data.user);
                setIsLoading(false);
            })
            .catch(function (error) {
                setUser(null);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={user?.email ? <Navigate to="/home"/> : <Landing/>}
                />
                <Route
                    path="/signup"
                    element={user?.email ? <Navigate to="/home"/> : <Signup/>}
                />
                <Route
                    path="/login"
                    element={user?.email ? <Navigate to="/home"/> : <Login/>}
                />
                <Route
                    path="/scan"
                    element={user?.email ? <Scan user={user}/> : <Navigate to="/"/>}
                />
                <Route
                    path="/view"
                    element={user?.email ? <View user={user}/> : <Navigate to="/"/>}
                />
                <Route
                    path="/manage"
                    element={user?.email ? <Manage user={user}/> : <Navigate to="/"/>}
                />
                <Route
                    path="/home"
                    element={user?.email ? <Scan user={user}/> : <Navigate to="/"/>}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;