import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {GoogleOAuthProvider} from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./scss/custom.scss"
// import "@coreui/coreui/dist/css/coreui.min.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <GoogleOAuthProvider clientId="74368283806-tu8b1fd0tgf2h1svj8rv6b9n184pgd9b.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
  // </React.StrictMode>}
);