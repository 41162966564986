import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from "axios";
import globals from "../global";

const TagComponent = ({ tagVal, email, inventoryID, approvedUsers, setApprovedUsers, inventoryOwner, onDestroy }) => {
    async function deleteUser(inventoryID) {
        const body = {
            "deny": [tagVal]
        }
        // Replace with your actual API endpoint
        await axios.patch(`${globals.server_address}/inventories/${inventoryID}/approved-users`, body, {
                withCredentials: true
            })
            .then(function (res) {
            })
            .catch(function (error) {
                console.log(error);
            });
        if (approvedUsers.includes(tagVal)) {
            setApprovedUsers((prevItems) => prevItems.filter(item => item !== tagVal));
        }
    }

    if (tagVal !== email) {
        if (tagVal === inventoryOwner) {
            return (
                <span className="badge rounded-pill text-bg-secondary mx-1">{tagVal} (Owner)</span>
            );
        } else {
            return (
                <span className="badge rounded-pill text-bg-secondary mx-1">{tagVal}<FaTimes type="button" className="mx-1" onClick={() => deleteUser(inventoryID)}/> </span>
            );
        }
    }

};

export default TagComponent;
