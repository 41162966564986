import React from 'react';
import {useEffect, useState, useRef} from "react"; // Importing necessary hooks from React
import axios from "axios"; // Importing axios for HTTP requests
import globals from "../global"; // Importing global variables
import EditableTextField from './EditableTextField'; // Importing EditableTextField component
import TagComponent from "./TagComponent"; // Importing TagComponent
import { FaPlus } from 'react-icons/fa'; // Importing font-awesome plus icon

const CardComponent = ({ title, text, email, onDestroy }) => {
    // State variables to manage various states within the component
    const [itemCount, setItemCount] = useState('Loading...');
    const [approvedUsers, setApprovedUsers] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [inventoryOwner, setInventoryOwner] = useState('');

    // Function to handle the removal of a user from approvedUsers
    function handleDestroy(id) {
        setApprovedUsers(approvedUsers.filter(item => item.id !== id));
    };

    // Function to delete an inventory item
    async function deleteInventory() {
        await axios.delete(`${globals.server_address}/users/${email}/inventories?id=${text}&name=${title}`, {
            withCredentials: true // Sending credentials with request
        })
            .then(function (res) {
                onDestroy(text); // Triggering onDestroy callback on success
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // Function to handle input change for tag addition
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Function to handle addition of a new tag
    const handleAddTag = (e, inventoryID, user) => {
        e.preventDefault();
        if (inventoryID && user) {
            const body = {
                "approve": [user]
            }
            axios.patch(`${globals.server_address}/inventories/${inventoryID}/approved-users`, body, {
                withCredentials: true // Sending credentials with request
            })
                .then(function (res) {
                    if (!approvedUsers.includes(body.approve[0])) {
                        setApprovedUsers((prevState) => [...prevState, body.approve[0]]);
                    }
                    setInputValue('');
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    // Function to get the item count of the inventory
    async function getInventoryItemCount(inventoryID) {
        await axios.get(`${globals.server_address}/inventories/${inventoryID}`, {
            withCredentials: true // Sending credentials with request
        })
            .then(function (res) {
                setItemCount(res.data.itemCount);
                setApprovedUsers(res.data.approvedUsers);
                setInventoryOwner(res.data.createdBy);
            })
            .catch(function (error) {
            });
    }

    // Function to focus the modal input field
    function focusModalInput() {
        const myModal = document.getElementById('addTagModal')
        const myInput = document.getElementById('tagInput')

        myModal.addEventListener('shown.bs.modal', () => {
            myInput.focus();
        });
    }

    // useEffect to fetch inventory item count on component mount
    useEffect(() => {
        getInventoryItemCount(text);
    }, []);

    return (
        <div className="col-sm-6 mb-3">
            <div className="modal fade" id="addTagModal" tabIndex="-1" aria-labelledby="addTagModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addTagModalLabel">Add Tag</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => handleAddTag(e, text, inputValue)}>
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    placeholder="Enter a tag"
                                    id="tagInput"
                                    className="w-100"
                                />
                                <button type="submit" hidden={true} data-bs-dismiss="modal">Add Tag</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setInputValue('')}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <EditableTextField initialText={title} id={text} isInventoryOwner={inventoryOwner === email}/>
                    <p className="card-subtitle mb-2 text-body-secondary">Item Count: {itemCount}</p>
                    <span className="card-text">Additional Users: </span>
                    {approvedUsers.map((tagVal) => (
                        <TagComponent
                            key={tagVal} // Ensuring unique key for each TagComponent
                            tagVal={tagVal}
                            email={email}
                            inventoryID={text}
                            approvedUsers={approvedUsers}
                            setApprovedUsers={setApprovedUsers}
                            inventoryOwner={inventoryOwner}
                            onDestroy={handleDestroy}
                        />
                    ))}
                    <i className="bi bi-plus-circle-fill small px-1 text-secondary" data-bs-toggle="modal" data-bs-target="#addTagModal" type="button" onClick={focusModalInput}></i>
                    <p></p>
                    <a className="btn btn-primary float-end mx-1">Placeholder</a>
                    <a className="btn btn-primary float-end mx-1">Placeholder</a>
                    <a className="btn btn-danger float-start mx-1" onClick={deleteInventory}>Delete</a>
                </div>
            </div>
        </div>
    );
};

export default CardComponent; // Exporting the component