// TrashCanRenderer.js
import React from 'react';
import globals from "../global";
import useInventoryStore from "../stores/useInventoryStore";
import axios from "axios";

const TrashCanRenderer = (props) => {
    const activeInventoryID = useInventoryStore((state) => state.activeInventoryID);

    const handleClick = () => {
        const { api, node } = props;

        axios.delete(`${globals.server_address}/inventories/${activeInventoryID}/items/${node.id}`, {
                withCredentials: true
            })
            .then(function (res) {
                api.applyTransaction({ remove: [node.data] });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <span style={{ cursor: 'pointer', color: 'red' }} onClick={handleClick}><i className="bi bi-trash3-fill"></i></span>
    );
};

export default TrashCanRenderer;
