import React from "react";
import Navbar from "./Navbar";
import {useState, useEffect} from "react";
import globals from "../global";
import axios from "axios";
import CardComponent from "./CardComponent";

function Manage({user}) {
    const [inventories, setInventories] = useState([]);

    function handleDestroy(id) {
        setInventories(inventories.filter(item => item.id !== id));
    };

    useEffect(() => {
        // Replace with your actual API endpoint
        axios.get(`${globals.server_address}/users/${user.email}/inventories`, {
                withCredentials: true
            })
            .then(function (res) {
                setInventories(res.data.inventories);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <Navbar user={user}/>
            <div className="container mt-4">
                <div className="row">
                    {inventories.map((item, index) => (
                        <CardComponent
                            key={index}
                            title={item.name}
                            text={item.id}
                            email={user.email}
                            onDestroy={handleDestroy}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Manage;