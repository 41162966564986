import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import globals from '../global.js';
import InventorySelection from "./InventorySelection";
import useInventoryStore from "../stores/useInventoryStore";
import axios from "axios";

const Scan = ({ user }) => {
    const [name, setName] = useState('');
    const [sku, setSKU] = useState('');
    const [qty, setQty] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const activeInventoryID = useInventoryStore((state) => state.activeInventoryID);
    const alertRef = React.useRef();

    const UseFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() };
        return [htmlElRef, setFocus];
    };

    const [nameRef, setNameFocus] = UseFocus();
    const [skuRef, setSKUFocus] = UseFocus();
    const [qtyRef, setQtyFocus] = UseFocus();

    const appendAlert = (message, type) => {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = `
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <div>${message}</div>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>`;
        alertRef.current.appendChild(wrapper);
    };

    function upsertInventory(name, sku, qty, action) {
        if (!name) { appendAlert('Please enter a name before submitting.', 'danger'); return; }
        if (!sku) { appendAlert('Please enter a SKU before submitting.', 'danger'); return; }
        if (!qty) { appendAlert('Please enter a quantity before submitting.', 'danger'); return; }

        if (action === 'out') qty = -qty;
        else name = undefined;

        setIsLoading(true);

        const body = {
            "lastCheckedOutBy": name,
            "qtyInc": parseInt(qty),
            "qtyCheckedOutInc": -qty,
        };

        axios.put(`${globals.server_address}/inventories/${activeInventoryID}/items/${sku}`, body,{
                withCredentials: true
            })
            .then(function (res) {
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                appendAlert('An error occurred, please check your input and try again.', 'danger');
                console.log(error);
            });

        setName('');
        setSKU('');
        setQty('');
        setNameFocus();
    }

    useEffect(() => {
        setNameFocus();
    }, []);

    const handleNameKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSKUFocus();
        }
    };

    const handleSKUKeyDown = (event) => {
        if (event.key === 'Enter') {
            setQtyFocus();
        }
    };

    const handleQtyKeyDown = (event) => {
        if (event.key === 'Enter') {
            setNameFocus();
        }
    };

    const handleFocus = (event) => {
        event.target.select();
    };

    return (
        <div>
            <Navbar user={user}/>
            <div className="container text-center my-5">
                <InventorySelection user={user} />
                <div className="row justify-content-center">
                    <div className="col-lg-4 mb-3">
                        <div className="input-group">
                            <span className="input-group-text bi bi-person-fill"></span>
                            <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} ref={nameRef} onKeyDown={handleNameKeyDown} onFocus={handleFocus} aria-label="Name" />
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="input-group">
                            <span className="input-group-text bi bi-upc-scan"></span>
                            <input type="text" className="form-control" placeholder="SKU" value={sku} onChange={(e) => setSKU(e.target.value)} ref={skuRef} onKeyDown={handleSKUKeyDown} onFocus={handleFocus} aria-label="SKU" />
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="input-group">
                            <span className="input-group-text bi bi-hash"></span>
                            <input type="number" className="form-control" placeholder="Quantity" value={qty} onChange={(e) => setQty(e.target.value)} ref={qtyRef} onKeyDown={handleQtyKeyDown} onFocus={handleFocus} aria-label="Quantity" />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <button className="btn btn-lg btn-danger px-5 m-2" disabled={isLoading} onClick={() => upsertInventory(name, sku, qty, 'out')} >Out</button>
                        <button className="btn btn-lg btn-success px-5 m-2" disabled={isLoading} onClick={() => upsertInventory(name, sku, qty, 'in')} >In</button>
                        <button className="btn btn-lg btn-info px-5 m-2" disabled={isLoading} onClick={() => upsertInventory(name, sku, qty, 'add')} >Add</button>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div ref={alertRef} className="mt-4"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Scan;