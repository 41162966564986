import React from "react";
import {Link} from "react-router-dom";
import {useGoogleLogin} from "@react-oauth/google";
import globals from '../global.js'
import axios from "axios";

// https://developers.google.com/identity/gsi/web/reference/js-reference

const SignUp = () => {
    const signup = useGoogleLogin({
        scope: globals.scopes,
        onSuccess: codeResponse => {
            axios.post(`${globals.server_address}/signup`, {
                    code: codeResponse.code
                }, {
                    withCredentials: true
                })
                .then(function (res) {
                    window.location.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        flow: 'auth-code',
    });

    return (
        <div>
            <div className="container text-center my-5">
                <div className="row my-5">
                    <h1>Inventory Management System</h1>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-light" onClick={() => signup()}><i className="bi bi-google"></i> Sign up with Google</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;