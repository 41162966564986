import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import axios from "axios";
import globals from "../global";

const Navbar = ({user}) => {

    const logout = async () => {
        await axios.post(`${globals.server_address}/logout`, {}, {
            withCredentials: true
        })
        localStorage.clear();
        window.location.reload();
    };

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">Inventory Management System</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">Dark offcanvas</h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 d-lg-flex align-items-center">
                            <li className="nav-item">
                                <NavLink to="/home" className="btn border-0">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/scan" className="btn border-0">Scan</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/view" className="btn border-0">View </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/manage" className="btn border-0">Manage</NavLink>
                            </li>
                            <li className="nav-item">
                                <button type="button" className="btn" onClick={logout}>Sign Out</button>
                            </li>
                            <li className="nav-item">
                                <img src={user?.picture} className="rounded-5" style={{height: 50, width: 50}}/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;