import React, {useEffect, useState, useRef, useContext, useCallback, useMemo} from "react";
import Navbar from "./Navbar";
import globals from '../global.js'
import useInventoryStore from '../stores/useInventoryStore.js'
import InventorySelection from "./InventorySelection";
import TrashCanRenderer from "../functions/TrashCanRenderer";
import ImageRenderer from "../functions/ImageRenderer";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import '../plugins/ag-grid-theme-builder.css';
import upsertInventory from './Scan';
import axios from "axios";

const View = ({user}) => {
    let filter = "all";
    let activeInventoryID = useInventoryStore((state) => state.activeInventoryID);
    const setActiveInventory = useInventoryStore((state) => state.setActiveInventory)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [switchDisabled, setSwitchDisabled] = useState(false);
    const [rowClassRules, setRowClassRules] = useState({})
    const [name, setName] = useState('');
    const [sku, setSKU] = useState('');
    const [qty, setQty] = useState('');
    const [minQty, setMinQty] = useState('');
    const [qtyCheckedOut, setQtyCheckedOut] = useState('');
    const gridRef = useRef(null);

    const getRowId = useCallback((params) => params.data.sku, []);

    const [colDefs, setColDefs] = useState([
        { headerName: 'SKU', field : "sku", flex: 1, filter: true, cellDataType: 'text', cellStyle: { textAlign: 'left' } },
        { headerName: 'Checked Out By', field : "lastCheckedOutBy", flex: 1, filter: true, editable: true, cellDataType: 'text', cellStyle: { textAlign: 'left' } },
        { headerName: 'Quantity', field : "qty", flex: 1, filter: true, editable: true, type: 'numericColumn', cellDataType: 'number' },
        { headerName: 'Min. Quantity', field : "minQty", flex: 1, filter: true, editable: true, type: 'numericColumn', cellDataType: 'number' },
        { headerName: 'Quantity Checked Out', field : "qtyCheckedOut", flex: 1, filter: true, editable: true, type: 'numericColumn', cellDataType: 'number' },
        {
            headerName: '',
            field: 'delete',
            cellRenderer: TrashCanRenderer,
            width: 50,
            sortable: false,
            filter: false,
            pinned: 'right',
            resizable: false
        },
    ]);

    const autoSizeStrategy = {
        type: 'fitGridWidth',
        defaultMinWidth: 100,
    };

    const externalFilterChanged = useCallback((newValue) => {
        filter = newValue;
        gridRef.current.api.onFilterChanged();
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        // if filter is not all, then we are filtering
        return filter !== "all";
    }, []);

    const doesExternalFilterPass = useCallback(
        (node) => {
            if (node.data) {
                switch (filter) {
                    case "lowInventory":
                        setSwitchDisabled(true);
                        return node.data.qty < node.data.minQty;
                    default:
                        setSwitchDisabled(false);
                        return true;
                }
            }
            return true;
        },
        [filter],
    );

    const onCellEditRequest = useCallback( (event) => {
        let body = {};
        const field = event.colDef.field;
        const sku = event.data.sku;
        const oldData = event.data;
        const newValue = event.newValue;
        const newData = {...oldData};
        newData[field] = newValue;

        if (!activeInventoryID) {
            return;
        }

        // await userApprovedForInventory();

        if (typeof newValue === 'number') {
            body = {
                [field]: parseFloat(newValue)
            };
        } else {
            body = {
                [field]: newValue
            };
        }

        const tx = {
            update: [newData],
        };

        setIsLoading(true)

        axios.put(`${globals.server_address}/inventories/${activeInventoryID}/items/${sku}`, body, {
            withCredentials: true
        })
            .then(function (res) {
                event.api.applyTransaction(tx);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
            });
    }, []);

    const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setSwitchChecked(isChecked);
        if (isChecked) {
            setRowClassRules({
                'bg-danger bg-opacity-50': (params) => params.data.qty < params.data.minQty,
            });
        } else {
            setRowClassRules({});
        }
    };

    // async function userApprovedForInventory() {
    //     if (!activeInventoryID) {
    //         return;
    //     }
    //
    //     axios.get(`${globals.server_address}/inventories/${activeInventoryID}/approved-users`, {
    //         withCredentials: true
    //     }).then(function (res) {
    //         if (res.data.approvedUsers.includes(user.email)) {
    //             return;
    //         } else {
    //             window.location.reload();
    //         }
    //     }).catch(function (error) {
    //         console.log(error);
    //         window.location.reload();
    //     });
    // }

    function refreshInventoryData() {
        if (!activeInventoryID) {
            return;
        }

        setIsLoading(true)
        axios.get(`${globals.server_address}/inventories/${activeInventoryID}/items?returnType=array`, {
            withCredentials: true
        })
            .then(function (res) {
                setTableData(res.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
            });
    }

    function upsertInventory(name, sku, qty, minQty, qtyCheckedOut) {
        const body = {
            "lastCheckedOutBy": name,
            "qty": parseInt(qty) || 0,
            "qtyCheckedOut": parseInt(qtyCheckedOut) || 0,
            "minQty": parseInt(minQty) || 0,
        };

        axios.put(`${globals.server_address}/inventories/${activeInventoryID}/items/${sku}`, body, {
            withCredentials: true
        })
            .then(function (res) {
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        refreshInventoryData();
    },[activeInventoryID]);

    return (
        <div>
            <Navbar user={user}/>
            <div className="container text-center my-5">
                <InventorySelection user={user}/>
                <div className="row">
                    <div className="col-4"></div>
                    <label className="col">
                        <input
                            type="radio"
                            name="filter"
                            id="everyone"
                            onChange={() => externalFilterChanged("everyone")}
                            className="mx-1"
                        />
                        All
                    </label>
                    <label className="col">
                        <input
                            type="radio"
                            name="filter"
                            id="lowInventory"
                            onChange={() => externalFilterChanged("lowInventory")}
                            className="mx-1"
                        />
                        Low Inventory
                    </label>
                    <div className="col-2"></div>
                    <div className="col form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={switchChecked} onChange={handleSwitchChange} disabled={switchDisabled}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Highlight Low Inventory</label>
                    </div>
                </div>
                <div className="ag-theme-custom">
                    <AgGridReact
                        ref={gridRef}
                        rowData={tableData}
                        columnDefs={colDefs}
                        readOnlyEdit={true}
                        onCellEditRequest={onCellEditRequest}
                        getRowId={getRowId}
                        autoSizeStrategy={autoSizeStrategy}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        rowClassRules={rowClassRules}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20, 50, 100]}
                        rowSelection="multiple"
                        domLayout="autoHeight"
                    />
                </div>

                <div className="d-flex justify-content-end">
                    {/*<button className="btn btn-primary m-2" data-bs-toggle="modal" data-bs-target="#importModal">*/}
                    {/*    <i className="bi bi-upload"></i> Bulk Import*/}
                    {/*</button>*/}
                    <button className="btn btn-success my-2" data-bs-toggle="modal" data-bs-target="#addItemModal">
                        <i className="bi bi-plus"></i> Add Item
                    </button>
                </div>


                <div className="modal fade" id="addItemModal" tabIndex="-1" aria-labelledby="addItemModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="addItemModalLabel">Add Item</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="col mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text bi bi-person-fill"></span>
                                        <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} aria-label="Name" />
                                    </div>
                                </div>
                                <div className="col mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text bi bi-upc-scan"></span>
                                        <input type="text" className="form-control" placeholder="SKU" value={sku} onChange={(e) => setSKU(e.target.value)} aria-label="SKU" />
                                    </div>
                                </div>
                                <div className="col mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text bi bi-hash"></span>
                                        <input type="number" className="form-control" placeholder="Quantity" value={qty} onChange={(e) => setQty(e.target.value)} aria-label="Quantity" />
                                    </div>
                                </div>
                                <div className="col mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text bi bi-hash"></span>
                                        <input type="number" className="form-control" placeholder="Min. Quantity" value={minQty} onChange={(e) => setMinQty(e.target.value)} aria-label="Minimum Quantity" />
                                    </div>
                                </div>
                                <div className="col mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text bi bi-hash"></span>
                                        <input type="number" className="form-control" placeholder="Quantity Checked Out" value={qtyCheckedOut} onChange={(e) => setQtyCheckedOut(e.target.value)} aria-label="Quantity Checked Out" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => upsertInventory(name, sku, qty, minQty, qtyCheckedOut)}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="modal fade" id="importModal" tabIndex="-1" aria-labelledby="importModalLabel" aria-hidden="true">*/}
                {/*    <div className="modal-dialog modal-dialog-centered">*/}
                {/*        <div className="modal-content">*/}
                {/*            <div className="modal-header">*/}
                {/*                <h1 className="modal-title fs-5" id="importModalLabel">Bulk Import</h1>*/}
                {/*                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                {/*            </div>*/}
                {/*            <div className="modal-body">*/}
                {/*                <div className="input-group mb-3">*/}
                {/*                    <input type="number" className="form-control" placeholder="SKU #0001" aria-label="Username" />*/}
                {/*                        <span className="input-group-text">-</span>*/}
                {/*                    <input type="number" className="form-control" placeholder="SKU #9999" aria-label="Server" />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="modal-footer">*/}
                {/*                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*/}
                {/*                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => upsertInventory(name, sku, qty, minQty, qtyCheckedOut)}>Add</button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </div>
    );
};

export default View;