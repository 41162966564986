import React, {createContext, useCallback, useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable";
import useInventoryStore from '../stores/useInventoryStore.js'
import globals from '../global.js'
import axios from 'axios';

const Inventory = ({user}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [inventoryDropdownItems, setInventoryDropdownItems] = useState([]);
    const [value, setValue] = useState();
    const inventories = useInventoryStore((state) => state.inventories);
    const activeInventoryID = useInventoryStore((state) => state.activeInventoryID);
    const activeInventoryName = useInventoryStore((state) => state.activeInventoryName);
    const appendInventory = useInventoryStore((state) => state.appendInventory)
    const setActiveInventory = useInventoryStore((state) => state.setActiveInventory)
    const setInventories = useInventoryStore((state) => state.setInventories)

    const colourStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            color: "white",
            background: "rgb(33,37,41)",
            // boxShadow: state.isFocused ? 0 : 0,
            borderColor: 'rgb(73,80,87)',
            '&:hover': {
                // borderColor: 'white',
                borderColor: 'rgb(73,80,87)'
            }
        }),
        menuList: styles => ({
            ...styles,
            background: "rgb(33,37,41)",
        }),
        singleValue:(baseStyles) => ({
            ...baseStyles,
            color:'white',
        }),
        placeholder:(baseStyles) => ({
            ...baseStyles,
            color:'rgb(174,178,182)',
        }),
        input:(baseStyles) => ({
            ...baseStyles,
            color:'white',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? "rgb(44,48,52)"
                : isSelected
                    ? "rgb(61,64,68)"
                    : undefined,
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        })
    }

    function createSelectItems() {
        let itemsToSet = [];
        if (inventories?.length) {
            for (let i = 0; i < inventories.length; i++) {
                itemsToSet.push(createOption(inventories[i].name, inventories[i].id));
            }
        }
        setInventoryDropdownItems(itemsToSet);
    }

    function onDropdownSelected(newValue) {
        setIsLoading(true);
        if (!newValue?.key || !newValue?.value) {
            return;
        }

        axios.get(`${globals.server_address}/inventories/${newValue.key}/approved-users`, {
            withCredentials: true
        }).then(function (res) {
            if (res.data.approvedUsers.includes(user.email)) {
                axios.patch(`${globals.server_address}/users/${user.email}`, {
                    activeInventory: newValue.key
                },{
                    withCredentials: true
                }).then(function (res) {
                    setActiveInventory(newValue.key, newValue.value)
                    setIsLoading(false);
                }).catch(function (error) {
                    setIsLoading(false);
                    console.log(error);
                });
            } else {
                setActiveInventory(null, null);
                setIsLoading(false);
                window.location.reload();
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    async function createInventory(inventoryName) {
        setIsLoading(true);
        axios.post(`${globals.server_address}/inventories`, {
                email: user.email,
                name: inventoryName
            },{
                withCredentials: true
            })
            .then(function (res) {
                const newOption = createOption(res.data.inventory.name, res.data.inventory.id);
                setValue(newOption);
                setActiveInventory(res.data.inventory.id, res.data.inventory.name)
                appendInventory({"id": res.data.inventory.id, "name": res.data.inventory.name});
                setInventoryDropdownItems(prev => [...prev, newOption]);
                setIsLoading(false);
                return res.data.inventory.id;
            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
            });
    }

    const createOption = (label, id) => ({
        label,
        value: label,
        key: id
    })

    function refreshUserInventories() {
        try {
            setIsLoading(true);
            axios.get(`${globals.server_address}/users/${user.email}`, {
                    withCredentials: true
                })
                .then(function (res) {
                    if (res.data.activeInventory.id) {
                        const newOption = createOption(res.data.activeInventory.name, res.data.activeInventory.id);
                        setValue(newOption);
                    }
                    setActiveInventory(res.data.activeInventory.id, res.data.activeInventory.name);
                    setInventories(res.data.inventories);
                    setIsLoading(false);
                })
                .catch(function (error) {
                    setIsLoading(false);
                    console.log(error);
                });

        } catch (err) {
            console.log(err.response.data.message);
        }
    }

    useEffect(() => {
        refreshUserInventories();
    }, []);

    useEffect(() => {
        createSelectItems();
    },[inventories]);

    return (
        <div>
            <link href="https://cdn.datatables.net/v/bs5/dt-2.0.0/datatables.min.css" rel="stylesheet"/>
            <script src="https://cdn.datatables.net/v/bs5/dt-2.0.0/datatables.min.js"/>
            <div className="row">
                <div className="input-group mb-3">
                    <i className="input-group-text bi bi-table" id="basic-addon1"></i>
                    <CreatableSelect
                        isClearable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={(newValue) => { setValue(newValue);  onDropdownSelected(newValue)}}
                        onCreateOption={createInventory}
                        options={inventoryDropdownItems}
                        placeholder={<div>Type to create an Inventory...</div>}
                        value={value}
                        styles={colourStyles}
                        // theme={(theme) => ({
                        //     ...theme,
                        //     borderRadius: 0,
                        //     colors: {
                        //         ...theme.colors,
                        //         neutral20: "#745858", // border
                        //         neutral0: "#2c2121", // background
                        //         primary: "#2c2121", // selected
                        //         primary25: "#745858", // highlight
                        //         primary50: "#d0b495",
                        //         primary75: "#dfcdb9",
                        //         neutral5: "#3a2c2c",
                        //         neutral10: "#574242",
                        //         neutral30: "#916e6e",
                        //         neutral40: "#a78b8b",
                        //         neutral50: "#bda8a8",
                        //         neutral60: "#d3c5c5",
                        //         neutral70: "#e9e2e2",
                        //         neutral80: "#f4f0f0",
                        //         neutral90: "#f4f0f0",
                        //     },
                        // })}
                        className="form-floating "
                    />
                </div>
            </div>
        </div>
    );
};

export default Inventory;