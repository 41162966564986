import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware';

const useInventoryStore = create(
    persist(
        (set) => ({
            activeInventoryID: '',
            activeInventoryName: '',
            inventories: [],
            appendInventory: (o) => {
                set((state) => ({
                    inventories: [...state.inventories, o ]
                }))
            },
            setActiveInventoryID: (id) => {
                set({activeInventoryID: id})
            },
            setActiveInventoryName: (name) => {
                set({activeInventoryName: name})
            },
            setActiveInventory: (id, name) => {
                set({activeInventoryID: id, activeInventoryName: name})
            },
            setInventories: (o) => {
                set({inventories: o})
            }
        }),
        {
            name: 'inventoryStorage', // unique name for the storage
            storage: createJSONStorage(() => localStorage)
        }
    )
);

export default useInventoryStore;