import React from "react";
import { Link } from "react-router-dom";

const Landing = () => {
    return (
        <div>
            <div className="container text-center my-5">
                <div className="row my-5">
                    <h1>Inventory Management System</h1>
                </div>
                <div className="row">
                    <div className="col">
                        <Link className="btn px-5 m-2 btn-light" to="/signup">Sign Up</Link>
                        <Link className="btn px-5 m-2 btn-light" to="/login">Login</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;