import React, { useState } from 'react'; // Importing React and useState hook
import { FaEdit, FaCheck, FaTimes } from 'react-icons/fa'; // Importing font-awesome icons
import axios from 'axios'; // Importing axios for HTTP requests
import globals from '../global' // Importing global variables

const EditableTextField = ({ initialText, id, isInventoryOwner}) => {
    // State variables to manage editing state and text values
    const [isEditing, setIsEditing] = useState(false);
    const [originalText, setOriginalText] = useState(initialText);
    const [editedText, setEditedText] = useState(initialText);

    // Function to enable editing mode
    const handleEdit = () => setIsEditing(true);

    // Function to cancel editing and revert changes
    const handleCancel = () => {
        setEditedText(originalText);
        setIsEditing(false);
    };

    // Function to confirm changes and update text
    const handleConfirm = async () => {
        setIsEditing(false);
        const body = { "name": editedText } // Preparing payload for patch request
        try {
            await axios.patch(`${globals.server_address}/inventories/${id}`, body, {
                withCredentials: true // Sending credentials with request
            })
                .then(function (res) {
                    setOriginalText(editedText); // Update original text on success
                })
                .catch(function (error) {
                    setEditedText(originalText); // Revert to original text on error
                    console.log(error);
                });
        } catch (error) {
            console.error('Error updating text:', error);
            // Handle error if needed
        }
    };

    // CSS classes for styling buttons and input fields
    const buttonClasses = "border-0 bg-transparent input-group-text float-end col-1";
    const defaultInputClasses = "border-0 bg-transparent fw-bold h5 col-10 my-0";
    const editingInputClasses = "bg-transparent card-title fw-bold h5 col-10 my-0";

    return (
        <div className="row card-title my-0">
            <input
                type="text"
                value={editedText}
                readOnly={!isEditing} // Making input readonly if not editing
                onChange={(e) => setEditedText(e.target.value)} // Updating edited text on change
                className={isEditing ? editingInputClasses : defaultInputClasses} // Applying appropriate CSS classes
            />
            {isInventoryOwner ? (
                isEditing ? (
                    <>
                        <button onClick={handleConfirm} className={buttonClasses}>
                            <FaCheck /> // Confirm button with check icon
                        </button>
                        <button onClick={handleCancel} className={buttonClasses}>
                            <FaTimes /> // Cancel button with times icon
                        </button>
                    </>
                ) : (
                    <>
                        <span className="col-1" />
                        <button onClick={handleEdit} className={buttonClasses}>
                            <FaEdit />
                        </button>
                    </>
                )
            ) : (
                <></> // Rendering nothing if not the inventory owner
            )}
        </div>
    );
};

export default EditableTextField; // Exporting the component